.react-data-table-component {
    border-radius: 10px; /* Adjust the border radius value to match the customStyles */
  }


  .mainTable{
    width:93%;
    padding-bottom:3%;
    margin-left: 3%;
    padding-left: 12px;
    padding-right:12px;
    /* box-shadow: 0px 0px 3px #00000029; */
    border-radius: 5px;
    background: #ffffff;
}
.mainTable > header{
    display: none;
}

button.print {
    display: none;
}
.mainTableHeader{
    display: flex;
}

.data-table-extensions-filter {
    float: right;
    padding-top: 23px;
    padding-bottom: 20px;
    padding-right: 25px;
}
.data-table-extensions-filter > input {
    width: 350px;
    height: 40px;
    padding:5px;
    border-radius: 5px;
}

.mainTableHeader1{
    float: left;
    padding-top: 25px;
    padding-left: 30px;
    font-size: 22px;
    font-weight: 600;
}

.mainTableHeader2{
    float: right;
    padding-top: 30px;
    padding-right: 50px
}

.mainTableHeader3{
    float: right;
    padding-top: 30px;
    padding-right: 70px
}

.rdt_TableCol {
    color: black;
    font-weight: bolder;
    font-size: 14px;
    background-color: #F7FBFE;
    }